<template>
  <div id="app-h" v-if="portrait == true && mobile == true">
    <transition name="fade" mode="out-in">
      <!-- step 1 -->
      <div
        key="step-1"
        class="flex flex-col py-4 justify-start text-white h-full items-center"
        v-if="step == 1"
      >
        <img
          src="@/assets/logo-primary-white.webp"
          class="w-24 xs:w-32 z-10"
          alt="logo"
        />

        <h1 class="uppercase font-bold text-4xl mt-36 font-bebas">WELCOME!</h1>
        <p class="px-12 text-xl text-center font-thin">
          Collect as many items as you can to win a €100 Amazon Voucher
        </p>

        <img
          src="https://www.drupa.com/cgi-bin/md_drupa/lib/all/lob/return_download.cgi/dru2002_tm01_rgb01.jpg?ticket=g_u_e_s_t&bid=5175&no_mime_type=0"
          class="mt-8 w-24"
          alt=""
        />

        <button
          class="absolute bottom-0 mb-10 text-white border-4 py-2 px-10 border-primary font-bebas text-xl xs:text-2xl"
          @click="playGame"
        >
          Play
        </button>
      </div>

      <!-- step 2 -->
      <div key="step-2" v-if="step == 2">
        <header>
          <h1>SCORE: {{ score }}</h1>
          <nav>
            <img
              :class="{ visible: lives >= 1 }"
              src="@/assets/icon-life@2x.png"
            />
            <img
              :class="{ visible: lives >= 2 }"
              src="@/assets/icon-life@2x.png"
            />
            <img
              :class="{ visible: lives >= 3 }"
              src="@/assets/icon-life@2x.png"
            />
          </nav>
        </header>
        <transition name="zoom"
          ><h1 v-if="msg" class="game-msg" v-html="msg"></h1
        ></transition>
        <game :details="details" v-on:update-score="onUpdateScore" />
      </div>

      <!-- step 3 -->
      <div
        key="step-3"
        class="flex flex-col py-4 xs:py-8 justify-start h-full items-center"
        v-if="step == 3"
      >
        <img
          src="@/assets/logo-primary-white.webp"
          class="w-24 xs:w-32 z-10"
          alt="logo"
        />

        <div
          class="w-full absolute bottom-0 h-full flex items-center flex-col justify-center text-center bg-contain bg-bottom bg-no-repeat mt-4"
        >
          <div class="">
            <h1 class="font-bebas text-3xl">CONGRATULATIONS!</h1>
            <p class="text-xl">Your score</p>
            <h2 class="text-6xl text-primary mt-5 font-bebas">{{ score }}</h2>
          </div>
        </div>

        <button
          class="text-white z-10 py-2 px-10 border-primary border-4 font-bebas mt-auto text-xl xs:text-2xl"
          @click="step = 4"
        >
          Continue
        </button>
      </div>

      <!-- step 4 -->
      <div
        key="step-4"
        class="flex flex-col py-4 xs:py-8 justify-start h-full items-center"
        v-if="step == 4"
      >
        <img
          src="@/assets/logo-primary-white.webp"
          class="w-24 xs:w-32 z-10"
          alt="logo"
        />

        <div
          class="w-full absolute bottom-0 h-full flex items-center flex-col justify-center text-center bg-contain bg-center bg-no-repeat"
        >
          <div class="w-8/12 -mt-16">
            <p class="font-thin text-lg mb-4">
              Please enter your details to participate in the leaderboard.
            </p>
            <form action="" class="flex flex-col" @submit.prevent="formSubmit">
              <input
                class="bg-primary text-white placeholder-gray-300 text-lg p-2"
                v-model="form.name"
                placeholder="Full Name"
                type="text"
              />
              <input
                class="bg-primary text-white placeholder-gray-300 text-lg p-2 mt-1"
                v-model="form.email"
                placeholder="E-mail"
                type="text"
              />
              <p class="opacity-50 text-sm my-4">
                E-mail must be valid or we can't reach you if you win.
              </p>
              <button
                @click="formSubmit"
                class="text-white border-4 z-10 py-2 px-1 border-primary font-bebas mt-3 text-xl xs:text-2xl"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>

      <!-- step 5 -->
      <div
        key="step-5"
        class="flex flex-col py-8 justify-start h-full items-center"
        v-if="step == 5"
      >
        <div
          class="w-full absolute bottom-0 h-full flex items-center flex-col justify-center text-center bg-contain bg-center bg-no-repeat"
        >
          <div class="w-8/12 -mt-16">
            <h2 class="font-bebas text-2xl xs:text-3xl">LEADERBOARD</h2>
            <ul>
              <li
                class="border my-2 p-2 text-sm xs:text-lg bg-primary text-white flex items-center justify-between"
              >
                <i class="fas fa-trophy"></i>
                <p>{{ results[0].name }}</p>
                <span>{{ results[0].score }}</span>
              </li>
              <li
                v-for="result in results.slice(1, 4)"
                class="border my-2 p-2 text-sm xs:text-lg border-primary flex items-center justify-between"
              >
                <i class="fas fa-trophy text-primary"></i>
                <p>{{ result.name }}</p>
                <span>{{ result.score }}</span>
              </li>
            </ul>
            <nav class="flex items-center justify-center my-3">
              <a
                href=""
                class="bg-primary text-white w-10 xs:w-12 h-10 xs:h-12 text-xl rounded-full flex items-center justify-center"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                href=""
                class="bg-primary text-white w-10 xs:w-12 h-10 xs:h-12 text-xl mx-4 rounded-full flex items-center justify-center"
                ><i class="fab fa-instagram"></i
              ></a>
              <a
                href=""
                class="bg-primary text-white w-10 xs:w-12 h-10 xs:h-12 text-xl rounded-full flex items-center justify-center"
                ><i class="fab fa-twitter"></i
              ></a>
            </nav>
            <button
              @click="playAgain"
              class="text-white border z-10 py-2 px-12 border-primary font-bebas mt-3 text-xl xs:text-2xl"
            >
              Play Again
            </button>
          </div>
        </div>
      </div>
      <!-- / steps -->
    </transition>
  </div>
  <div
    class="text-white flex flex-col h-screen w-screen items-center justify-center"
    v-else
  >
    <img src="@/assets/logo-primary-white.webp" class="w-48 mb-16" alt="logo" />

    <h1 v-if="!mobile" class="font-bebas text-6xl">Mobile Only</h1>
    <h1 v-else class="font-bebas text-6xl">Please Rotate Device</h1>

    <img
      v-if="!mobile"
      src="https://query.appetitecreative.com/v2/public/qr?url=https://drupa.appetitecreative.com/"
      class="w-56 mt-4"
      alt=""
    />
    <p
      v-if="!mobile"
      class="uppercase font-montserrat opacity-50 font-thin mt-5 text-center text-sm"
    >
      Switch to mobile <br />and start playing!
    </p>
  </div>
</template>

<script>
import Phaser from "phaser";
import game from "./components/Game";
import axios from "axios";

export default {
  name: "App",
  components: {
    game,
  },
  data() {
    return {
      lives: 3,
      score: 0,
      gameover: false,
      play: false,
      msg: null,
      details: [],
      step: 1,
      portrait: true,
      mobile: true,
      form: {
        name: null,
        email: null,
        score: 0,
      },
      results: [],
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);

    var $app = document.getElementById("app-h"),
      h = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );

    $app.style.height = h + "px";
  },

  watch: {
    step: function(v) {
      if (v == 5) {
        this.getResults();
      }
    },
  },

  methods: {
    formSubmit() {
      let $this = this;
      let $url =
        "https://xhr.appetitecreative.com/appetite/game/appetite-drupa-2024/submit";

      $this.form.score = $this.score;

      axios.post($url, $this.form).then((r) => {
        if (r.data.success) {
          $this.step = 5;
        }
      });
    },

    getResults() {
      let $this = this;
      let $url =
        "https://xhr.appetitecreative.com/appetite/game/appetite-drupa-2024/results";

      $this.form.score = $this.score;

      axios
        .post($url, { key: "MkThw24PDVcQJcpTuRNb1IOMAi87neOd", limit: 4 })
        .then((r) => {
          $this.results = r.data.results;
          console.log(r.data.results);
        });
    },

    onResize() {
      if (window.innerWidth > window.innerHeight) {
        this.portrait = false;
      } else {
        this.portrait = true;
      }

      if (window.innerWidth > 767) {
        this.mobile = false;
      } else {
        this.mobile = true;
      }
    },

    onUpdateScore(o) {
      this.lives = o.lives;
      this.score = o.score;
      this.gameover = o.gameover;
      if (this.gameover) {
        this.play = false;
        this.step = 3;
      }
      if (o.msg) {
        this.onMsgUpdate(o.msg);
      }
    },

    playGame() {
      this.play = true;
      this.step = 2;
      this.onMsgUpdate(
        "TAP TO CATCH AS MANY ITEMS AS YOU CAN <br> <span>DOUBLE TAP TO JUMP HIGHER</span>",
        3000
      );
    },

    playAgain() {
      this.lives = 3;
      this.score = 0;
      this.play = true;
      this.step = 2;
      this.onMsgUpdate(
        "TAP TO CATCH AS MANY ITEMS AS YOU CAN <br> <span>DOUBLE TAP TO JUMP HIGHER</span>",
        3000
      );
    },

    onMsgUpdate(msg, timeout = 2000) {
      this.msg = msg;
      setTimeout(() => {
        this.msg = null;
      }, timeout);
    },
  },
};
</script>
