<template>
  <div :id="containerId" v-if="downloaded">
    <div class="line"></div>
  </div>
  <div class="placeholder" v-else>
    Loading ...
  </div>
</template>

<style lang="scss" scoped>
#game-container {
  position: absolute;
  background-image: url(../assets/bg-sand2.png);
  background-size: cover;
  background-position: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
</style>

<script>
import Phaser from "phaser";

export default {
  name: "Game",
  props: ["details"],
  data() {
    return {
      downloaded: false,
      gameInstance: null,
      containerId: "game-container",
    };
  },
  async mounted() {
    const game = await import("../game");
    this.downloaded = true;
    this.$nextTick(() => {
      let em = new Phaser.Events.EventEmitter();
      em.addListener("update-score", (v) => this.$emit("update-score", v));

      //this.resize();
      //window.addEventListener("resize", this.resize, false);

      this.gameInstance = game.launch(this.containerId, em, this.details);
    });
  },
  destroyed() {
    this.gameInstance.destroy(false);
  },
  methods: {
    resize() {
      let canvas = document.querySelector("#game-container canvas");
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      let windowRatio = windowWidth / windowHeight;
      let gameRatio = window.screen.width / window.screen.height;
      if (windowRatio < gameRatio) {
        canvas.style.width = windowWidth + "px";
        canvas.style.height = windowWidth / gameRatio + "px";
      } else {
        canvas.style.width = windowHeight * gameRatio + "px";
        canvas.style.height = windowHeight + "px";
      }
    },
  },
};
</script>
